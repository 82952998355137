<template>
  <div>
    <input
        class="input"
        ref="input"
        :type="type"
        :value="value"
        min="0"
        :maxlength="maxLength"
        @keyup="chkValid"
        @keyup.enter="$emit('onEnter')"
        @blur="chkValid"
        :placeholder="placeholder"
        @input="e => onKeyup(e.target.value)"
        :disabled="disabled"/>
  </div>
</template>
<script>
  export default {
    name: "LpNumber",
    props: {
      val: {
        type: [String, Number]
      },
      type: {
        type: String,
        default: 'text'
      },
      disabled: {
        type: Boolean,
        required: false
      },
      maxLength: {
        type: Number,
        required: 100
      },
      placeholder: {
        type: String,
        default: ''
      },
      validRequired: {
        type: Boolean,
        default: false
      }
    },
    created() {
    },
    data() {
      return {
        value: ''
      }
    },
    methods: {
      onKeyup(value) {
        this.value = value;
        if(this.type==='phone') {
          this.value = this.autoHyphenPhone(value);
        } else if(this.type==='number') {
          //
        }
      },
      chkValid() {
        if(this.error) {
          //
        }
      }
    }
  }
</script>
<style lang="stylus" scoped>
</style>